<template>
	<div card class="p-5 bg-white">
		<div card-title>
			<h3 class="mb-10 font-weight-bold text-dark">
				API Key for: <span class="font-italic">{{ data.username }}</span>
			</h3>
		</div>
		<div card-body>
			<div class="row" style="display: flex; flex-direction: column; margin: 0 5%">
                <div class="form-group">
				    <b-form-input style="width:30%;" type="text" :value='data.api_key' readonly />
                </div>
                <a target="_blank" :href="`https://portal.itcglobal.net/api/doc/index.html?swaggerurl=/api/swagger?api_key=${data.api_key}`">
                    Link to API spec
                </a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['data'],
	data() {
		return {
			
		};
	},
};
</script>

<style>
</style>